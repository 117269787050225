<template>
    <div class="block">
      <div class="title">
        <el-row>
            <el-col :span="24"><p>检查人员：{{ info.check_users }}</p> </el-col>
            <el-col :span="24">
                <p>
                    <span>检查时间：{{info.add_time}}</span>
                    <span v-if="info.status==1"> 至 {{ info.complete_time }}</span>
                </p>
            </el-col>
            <el-col :span="12" v-if="info.status==1">检查状态：<el-tag v-if="info.status==1">完成</el-tag><el-tag v-else>未完成</el-tag></el-col>
            <el-col :span="12" v-if="info.status==1">检查结果:<el-tag v-if="info.form_is_normal==1">正常</el-tag><el-tag v-else>异常</el-tag> </el-col>
        </el-row>
      </div>
      <el-timeline> 
        <el-timeline-item
          v-for="(item, index) in operation_records"
          :key="index"
          :timestamp="item.date"
          placement="top">
          <el-card>
            <h4>{{item.type_name}} <span v-if="item.type_name">:</span> {{ item.remark }}</h4>
            <p>{{ item.nickname }} <el-tag v-if="item.result">{{item.result}}</el-tag></p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </template>
  
  <script >
  import { text } from "body-parser";
  import { spectionControlDetail } from "../../assets/request/api";
  export default {
    data() {
        return {
            info: {},
            operation_records: "",
        };
    },
    methods: {
        way_show(id) {
            spectionControlDetail({ id: id }).then((res) => {
                let info = res.data;
                this.info = info;
                this.operation_records = info.operation_records;
            });
        },
    },
    components: { text }
};
  </script>
  <style lang="less" scoped>
  .block {
    height: 50vh;
    overflow-y: scroll;
  }
  .title {
    margin-bottom: 10px;
    font-size: 14px;
    p {
      margin-bottom: 10px;
    }
  }
  </style>
  